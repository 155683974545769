import PubNub from 'pubnub'
import { ProviderFields } from '@fireflyhealth/core'
import { FC, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '~/components/Providers/ApiProvider'
import chatSlice from '~/redux/slices/chat'
import { apiClient } from '~/api/rest'

export const providerInboxChannel = (me: { providerFields: ProviderFields }) => {
  return 'provider-inbox-tenant-' + me.providerFields.tenant
}

export const disconnectFromPubNub = (
  pubNub: PubNub,
  me: { providerFields: ProviderFields; id: number }
) => {
  // Clear presence state and unsubscribe from all channels
  if (me) {
    updatePresence(pubNub, me, { typing: false, viewing: false })
  }
  if (pubNub) {
    pubNub.unsubscribeAll()
  } else {
    console.warn('[Session] Unable to disconnect from PubNub, no client found')
  }
}

interface PresenceState {
  patientId?: number
  typing?: boolean
  viewing?: boolean
}

// Codes for what different PubNub signals mean
type PresenceSignalTypeCodes = 'rf'
export const PresenceSignalTypeCodeMap: { [key: string]: PresenceSignalTypeCodes } = {
  MESSAGE_REFILTER: 'rf',
}

// Use short prop names to keep payload size small
// https://www.pubnub.com/docs/sdks/redux/api-reference/signals
export interface PresenceSignalMessage {
  p: number // Patient User ID
  pe?: number // PErson ID
  st?: PresenceSignalTypeCodes // signal type
  v?: boolean // is viewing?
  t?: boolean // is typing?
}

export const updatePresence = (
  pubNub: PubNub,
  me: { providerFields: ProviderFields; id: number },
  state: PresenceState
) => {
  if (pubNub && me) {
    // https://www.pubnub.com/docs/sdks/redux/api-reference/signals
    pubNub.signal(
      {
        channel: providerInboxChannel(me),
        message: {
          v: state.viewing,
          t: state.typing,
          p: state.patientId,
        },
      },
      status => {
        if (status.error) {
          console.error('Error in updatePresence', status)
        }
      }
    )
  }
}

export const usePubNubUtils = () => {
  const pubNub = useSelector((state: any) => state.chat?.pubnub)
  // Assert that provider details have been loaded
  const me = useSelector(state => state.me) as { providerFields: ProviderFields; id: number }
  const dispatch = useDispatch()
  const reAuthenticationTimeoutIdRef = useRef<NodeJS.Timeout>()

  const cancelReAuthentication = () => {
    if (reAuthenticationTimeoutIdRef.current) clearTimeout(reAuthenticationTimeoutIdRef.current)
  }

  const { mutate } = useMutation(
    () =>
      apiClient.rest.post<{ accessTtl: number; authKey: string; uuid?: string }>(
        `/user/providers/me/authenticate_chat/`
      ),
    {
      onSuccess: data => {
        dispatch(chatSlice.thunks.initPubnub(me, data.authKey))
        // We want to fetch a new token ahead of the token's TTL
        // to silently keep the user authenticated as long as they're active
        reAuthenticationTimeoutIdRef.current = setTimeout(() => {
          if (me) {
            mutate()
          }
        }, Math.floor(data.accessTtl / 2))
      },
    },
    { error: 'Failed to authenticate with chat service' }
  )

  return {
    disconnectFromPubNub: () => {
      disconnectFromPubNub(pubNub, me)
      cancelReAuthentication()
    },
    updatePresence: state => updatePresence(pubNub, me, state),
    authenticate: mutate,
  }
}

export const withPubNubUtils = (Component: any): FC => {
  const ComponentWrapper = (props): JSX.Element => {
    const pubNubUtils = usePubNubUtils()
    return <Component {...props} pubNubUtils={pubNubUtils} />
  }
  return ComponentWrapper
}

export interface IPatientInsuranceDetails {
  coverageStartDate: string | null
  coverageEndDate: string | null
  insurancePayerName: string | null
  insurancePayerShortName?: string | null
}

export interface ICareTeam {
  ids: number[]
  nursePractitioner: {
    id: number
  }
}

export interface IPubNubCareTeam {
  ids: number[]
  nurse_practitioner: {
    id: number
  }
}

export interface IPubNubInsuranceDetails {
  coverage_start_date: string | null
  coverage_end_date: string | null
  insurance_payer_name: string | null
  insurance_payer_short_name: string | null
}

export interface IPubNubPatientMetadata {
  /* eslint-disable camelcase */
  id: string
  first_name: string
  last_name: string
  enrolled_in_primary_care_program: boolean
  enrolled_in_benefit_program: boolean
  onboarding_state_signup_reason: string | null
  onboarding_state_status: string | null
  address_state: string | null
  coverage_state: any
  rtw_status: string | null
  test_only: boolean
  patient_insurance_details: IPubNubInsuranceDetails
  care_team: IPubNubCareTeam
  tenant_id: number
  is_redacted?: boolean
  pod_ids: number[]
}
