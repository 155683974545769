import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Link, Route } from 'react-router-dom'
import {
  formatDateTimeDay,
  formatDateTimeFull,
  getFormSubmissionStatus,
  getIsVirtualTask,
  getTaskStatus,
} from './utils'
import { useEffect, useState } from 'react'

import { Delete } from '@mui/icons-material'
import type { FC } from 'react'
import FormSubmission from './FormSubmission'
import FormSubmissionCreator from './FormSubmissionCreator'
import Moment from 'moment'
import type { PatientUser } from '~/types'
import { TaskCollectionTask } from '@fireflyhealth/core'
import UserAvatar from '~/components/Generic/UserAvatar'
import { apiClient } from '~/api/rest'
import { parse } from 'date-fns'
import { useMutation } from '~/utils/useMutation'
import { useFeatureSwitch } from '~/utils/useFeatureSwitch'

// TODO: Add typings for these resources.
const formSubmissionsResource = apiClient.resource<any>('/form/submission/')
const tasksResource = apiClient.resource<any>('/task/v2/')

interface PatientDetailFormsProps {
  patient: PatientUser
}

const PatientDetailForms: FC<PatientDetailFormsProps> = props => {
  const user = props.patient

  // TODO: Put this state into redux instead of forcing refetches manually.
  const [mutated, setMutated] = useState(0)

  const fetchAssignments = useMutation<[void], any[], any>(
    async () => {
      const [virtualTasks, forms] = await Promise.all([
        apiClient.tasks.getTasks({ patient: user.id, sourceType: 'virtual' }),
        formSubmissionsResource.getAll({ user: user.id }),
      ])
      return virtualTasks.results.concat(forms)
    },
    { error: 'Failed to fetch assigned tasks and forms' }
  )

  // Re-fetch whenever state mutates
  useEffect(() => {
    fetchAssignments.handler()
  }, [mutated])

  const deleteSubmission = useMutation(
    async payload => {
      const isVirtualTask = getIsVirtualTask(payload)
      await (isVirtualTask ? tasksResource : formSubmissionsResource).delete(payload.id)
      setMutated(m => m + 1)
      return true
    },
    { success: 'Assignment removed', error: 'Failed to remove assignment' }
  )

  // Create form submission and a patient facing task to complete the form submission
  const createFormSubmission = useMutation(
    async ({ form, dueDate }) => {
      const submission = await formSubmissionsResource.create({
        user: user.id,
        form: form.id,
      })

      await Promise.all([
        tasksResource.create({
          title: form.title,
          ...{ ownerGroup: user.assigneeGroup?.id },
          patient: user.id,
          dueDate: Moment(dueDate).format(),
          sourceType: 'formsubmission',
          relations: [
            {
              isParent: true,
              contentType: 'formsubmission',
              objectId: submission.id,
            },
          ],
        }),
      ])
      setMutated(m => m + 1)
      return true
    },
    { error: 'Failed to assign form.', success: 'Form assigned.' }
  )

  /**
   * Separate call for submitting virtual tasks
   */
  const assignVirtualTask = useMutation<[TaskCollectionTask], true, any>(
    async template => {
      await apiClient.rest.post(`/task/templates/${template.id}/patients/${user.id}/`)
      setMutated(m => m + 1)
      return true
    },
    { success: 'Virtual task assigned.', error: 'Failed to assign virtual task.' }
  )

  // Sort assignments by the date they were assigned
  const sortedAssignments = (fetchAssignments.result ?? []).sort(
    (a, b) => parse(b.createdAt).getTime() - parse(a.createdAt).getTime()
  )

  const useNewInsuranceScreens = useFeatureSwitch('insurance.new_insurance_screens')?.active

  return (
    <Box p={2}>
      <Route exact path="/patients/:id/forms">
        <FormSubmissionCreator
          loading={createFormSubmission.loading || assignVirtualTask.loading}
          onTaskTemplateSubmit={assignVirtualTask.handler}
          onFormSubmit={createFormSubmission.handler}
          careTeam={user.person.careTeam}
          patient={user}
        />

        <h3>All Forms</h3>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Assigned By</TableCell>
              <TableCell>Assigned On</TableCell>
              <TableCell>Completed On</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAssignments.map(submission => {
              const isVirtualTask = getIsVirtualTask(submission)
              return (
                <TableRow key={submission.id}>
                  <TableCell>
                    {isVirtualTask ? (
                      submission.uid ?? submission.title
                    ) : !submission.form.submissionViewable ? (
                      submission.form.title
                    ) : submission.metadata?.url && submission.form.submissionViewable ? (
                      <a href={submission.metadata.url} target="_blank" rel="noopener noreferrer">
                        {submission.uid ?? submission.title}
                      </a>
                    ) : submission.form.submissionViewable ? (
                      <Link to={`/patients/${user.id}/forms/submissions/${submission.id}`}>
                        {submission.form.title}
                      </Link>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {(isVirtualTask ? getTaskStatus : getFormSubmissionStatus)(submission)}
                  </TableCell>
                  <TableCell>
                    {submission.createdBy ? (
                      <UserAvatar id={submission.createdBy} size="small" />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" title={formatDateTimeFull(submission.createdAt)}>
                      {formatDateTimeDay(submission.createdAt)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {submission.completedAt ? (
                      <>
                        <Typography
                          variant="body2"
                          title={formatDateTimeFull(submission.createdAt)}
                        >
                          {formatDateTimeDay(submission.completedAt)}
                        </Typography>
                      </>
                    ) : (
                      <Typography>-</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {!submission.completedAt &&
                    !(
                      !isVirtualTask &&
                      !useNewInsuranceScreens &&
                      submission.form.title == 'Insurance Information'
                    ) ? (
                      <IconButton size="small" onClick={() => deleteSubmission.handler(submission)}>
                        <Delete />
                      </IconButton>
                    ) : null}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Route>

      <Route path="/patients/:id/forms/submissions/:submissionId">
        <FormSubmission user={user} />
      </Route>
    </Box>
  )
}

export default PatientDetailForms
