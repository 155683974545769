import { Close } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { AvailablePhysicianSlot, VisitReason } from '~/api/AppointmentService'
import * as AppointmentService from '~/api/AppointmentService'
import FFModal from '~/components/FFModal/FFModal'
import Loader from '~/components/Loader'
import { queueNotification } from '~/redux/actions/notifications'
import BookAppointment from './BookAppointment'
import BookingReason from './BookingReason'

type Step = 'book' | 'reason'

type Props = {
  patientId: number
  onClose: () => void
  openModal?: boolean
}

const BookAppointmentModalWindow: FC<Props> = ({ patientId, onClose, openModal }) => {
  const now = new Date()
  const [step, setStep] = useState<Step>('book')
  const [visitReason, setVisitReason] = useState<VisitReason | null>(null)
  const [date, setDate] = useState(now)
  const [slot, setSlot] = useState<AvailablePhysicianSlot | null>(null)
  const [apptReason, setApptReason] = useState<string>('')
  const [saving, setSaving] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const dispatch = useDispatch()
  const client = useQueryClient()

  const handleBookAppointment = async () => {
    if (apptReason == '') {
      return
    }
    setSaving(true)
    const payload = {
      elationId: slot?.elationId,
      description: apptReason,
      patientId: patientId,
      appointmentId: slot?.id,
    }
    try {
      const apptBookData = await AppointmentService.bookAppointment(payload)
      if (apptBookData.patient !== null) {
        dispatch(
          queueNotification({
            message: `Appointment booked successfully`,
            variant: 'success',
          })
        )
      } else {
        dispatch(
          queueNotification({
            message: `Failed to book the appointment`,
            variant: 'error',
          })
        )
      }
    } catch (error) {
      dispatch(
        queueNotification({
          message: `Failed to book the appointment`,
          variant: 'error',
        })
      )
    }
    await client.invalidateQueries(['getPatientAppointmentsInfo', patientId])
    await client.invalidateQueries(['useAppointmentLogs', patientId])
    setSaving(false)
    onClose()
  }

  const stepBook = () => {
    return (
      <Box my={1} mx={2} display="flex" justifyContent="flex-end">
        <Button
          onClick={() => {
            onClose()
          }}
          variant="text"
          // disabled={saving}
          size="small"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
            maxHeight: '75%',
            width: '20%',
            marginRight: 1,
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Cancel'}</Typography>
        </Button>
        <Button
          disabled={slot ? false : true}
          onClick={() => {
            setStep('reason')
          }}
          variant="contained"
          size="small"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Next'}</Typography>
        </Button>
      </Box>
    )
  }

  const stepReason = () => {
    return (
      <Box my={1} mx={2} display="flex" justifyContent="flex-end">
        <Box
          sx={{
            flexGrow: 1,
            display: 'inline-table',
          }}
        >
          <Button
            onClick={() => {
              setStep('book')
            }}
            variant="text"
            disabled={saving}
            size="small"
            sx={{
              borderRadius: 10,
              fontSize: '1.4rem',
              maxHeight: '75%',
              width: '20%',
              marginRight: 1,
            }}
          >
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Back'}</Typography>
          </Button>
        </Box>
        <Button
          onClick={() => {
            onClose()
          }}
          variant="text"
          disabled={saving}
          size="small"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
            maxHeight: '75%',
            width: '20%',
            marginRight: 1,
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Cancel'}</Typography>
        </Button>
        <Button
          disabled={saving || apptReason === ''}
          onClick={handleBookAppointment}
          variant="contained"
          size="small"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Book'}</Typography>
        </Button>
      </Box>
    )
  }

  return (
    <FFModal
      open={openModal == true || openModal == null}
      style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}
      footer={visitReason ? step == 'book' ? stepBook() : stepReason() : <></>}
    >
      {isLoading && <Loader />}
      <Box m={2}>
        <Typography variant="h6">{step == 'book' ? 'Book Appointment' : 'Add Reason'}</Typography>
      </Box>
      <Box m={2}>
        <IconButton
          onClick={() => {
            onClose()
          }}
          sx={{
            position: 'absolute',
            zIndex: 100,
            top: 2,
            right: 2,
          }}
        >
          <Close />
        </IconButton>
      </Box>
      {step === 'book' ? (
        <BookAppointment
          patientId={patientId}
          visitReason={visitReason}
          setVisitReason={setVisitReason}
          now={now}
          date={date}
          setDate={setDate}
          slot={slot}
          setSlot={setSlot}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      ) : (
        <BookingReason apptReason={apptReason} setApptReason={setApptReason} saving={saving} />
      )}
    </FFModal>
  )
}

export default BookAppointmentModalWindow
