import { Avatar, Box, Chip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { BehindProviderFeatureAccessCheck } from '@fireflyhealth/core'
import { Link } from 'react-router-dom'
import { Photo } from '@mui/icons-material'
import PrimaryCareChip from '~/components/PatientDetail/PrimaryCareChip/PrimaryCareChip'
import { SingleMessageData } from '~/screens/Messages/utils'
import UserAvatar from '../Generic/UserAvatar'
import classNames from 'classnames'
import { makeStyles } from 'tss-react/mui'
import { useFeatureAccess } from '~/utils/useFeatureAccess'
import { Badge } from '@mui/material'
import { AutoAwesome as AutoAwesomeIcon } from '@mui/icons-material'

interface IMessagesSingleProps {
  style: any
  message: SingleMessageData
}

const MessagesSingle = (props: IMessagesSingleProps) => {
  const { classes } = useStyles()
  const { style } = props
  const {
    attachment,
    read = true,
    timestamp,
    text,
    isUrgentMessage,
    lastSender,
    enrolledInPrimaryCareProgram = false,
    enrolledInBenefitProgram = false,
    onboardingStatus,
    addressState,
    coverageState,
    patientInitials,
    patientName,
    patientId,
    patientInsuranceDetails,
    careTeam,
  } = props.message

  const [unreadTextClass, setUnreadTextClass] = useState<string>('')
  const featureAccess = useFeatureAccess()

  useEffect(() => {
    setUnreadTextClass(read ? '' : classes.messageContentUnread)
  }, [props.message])

  return (
    <div style={style}>
      <Link to={`/patients/${patientId}`} className={classes.message}>
        <Avatar classes={{ root: `${classes.messageAvatar} ${classes.messageItem}` }}>
          {patientInitials}
        </Avatar>
        <Typography className={classNames(classes.id, unreadTextClass)}>{patientId}</Typography>
        <Typography
          className={classNames(
            'TEST_messageSingleName',
            classes.messageItem,
            classes.messageUser,
            classes.messageClippedText,
            unreadTextClass
          )}
        >
          {patientName}
        </Typography>
        {lastSender ? <UserAvatar id={lastSender} size="small" /> : null}

        {isUrgentMessage && (
          <Badge
            badgeContent=" "
            overlap="circular"
            variant="dot"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Chip
              icon={<UrgencyIcon />}
              size="small"
              label="Urgent"
              title={`Urgent: Urgent`}
              color="default"
              style={{
                alignItems: 'center',
                backgroundColor: '#EA5329',
                marginLeft: '1.0rem',
                color: 'white',
                display: 'inline-flex',
                width: '85px',
                paddingRight: '30px',
                marginRight: '10px',
              }}
            />
          </Badge>
        )}
        <Typography
          className={classNames(
            'TEST_messageSingleText',
            classes.messageItem,
            classes.messageContent,
            classes.messageClippedText,
            unreadTextClass
          )}
        >
          {attachment ? (
            <>
              <Photo className={classes.messageItemAttachment} />
              {text || 'IMAGE ATTACHMENT'}
            </>
          ) : (
            text
          )}
        </Typography>
        <BehindProviderFeatureAccessCheck feature="programs" featureAccess={featureAccess}>
          <Box className={classNames(classes.messageItem)}>
            <PrimaryCareChip
              styles={{ marginRight: '8px' }}
              onboardingStatus={onboardingStatus}
              patientIsInPrimaryCareProgram={enrolledInPrimaryCareProgram}
              patientIsInBenefitProgram={enrolledInBenefitProgram}
              coverage={coverageState}
              patientInsuranceInfo={patientInsuranceDetails}
            />
          </Box>
        </BehindProviderFeatureAccessCheck>
        {careTeam && careTeam.nursePractitioner && careTeam.nursePractitioner.id && (
          <UserAvatar id={careTeam.nursePractitioner.id} size="small" />
        )}
        <Box className={classNames(classes.messagePatientState)}>
          {addressState && <Chip label={addressState} size="small" variant="outlined" />}
        </Box>
        <Typography className={`${unreadTextClass}`}>{timestamp}</Typography>
      </Link>
    </div>
  )
}

const useStyles = makeStyles()(theme => ({
  id: {
    marginRight: theme.spacing(2),
    width: '5rem',
  },
  message: {
    textDecoration: 'none',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `0.1rem solid ${theme.palette.grey['A100']}`,
    '&:hover': {
      backgroundColor: theme.palette.grey['A50'],
    },
  },
  messageItem: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  messageItemAttachment: {
    marginRight: theme.spacing(),
  },
  messageAvatar: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
    fontSize: '1.5rem',
    textTransform: 'uppercase',
  },
  messageUser: {
    width: '16rem',
  },
  messageContent: {
    flex: 1,
  },
  messageContentUnread: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  messageClippedText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  messagePatientState: {
    width: '3.5rem',
    marginRight: theme.spacing(),
  },
  messageTimestamp: {
    width: '10 rem',
  },
  messageUrgency: {
    width: '10 rem',
  },
}))

const UrgencyIcon = () => (
  <span
    style={{
      display: 'inline-flex',
      color: 'white',
      position: 'absolute',
      marginLeft: '65px',
      paddingLeft: '3px',
    }}
  >
    <AutoAwesomeIcon fontSize="small"></AutoAwesomeIcon>
  </span>
)

export default MessagesSingle
