import { FeatureAccess, ProviderFields } from '@fireflyhealth/core'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/rest'

const getFeatureAccess = (providerFields: ProviderFields) =>
  apiClient.rest.get<FeatureAccess>(
    `/features/v2/access/?tenant_key=${providerFields.tenantKey}&provider_context=true`
  )

// Check and cache feature access for the current tenant
const useFeatureAccess = () => {
  const me = useSelector(state => state.me)
  const { data: featureAccess } = useQuery(
    ['featureAccess', me?.providerFields?.pk],
    () => getFeatureAccess(me?.providerFields!),
    {
      staleTime: 24 * 60 * 60 * 1000, // 1 day
      enabled: !!me?.providerFields,
    }
  )

  return featureAccess
}

export { useFeatureAccess }
