import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { AvailablePhysicianSlot, VisitReason } from '~/api/AppointmentService'
import SelectAppointmentType from './SelectAppointmentType'
import SelectDate from './SelectDate'
import SelectSlot from './SelectSlot'

type BookAppointmentProps = {
  patientId: number
  visitReason: VisitReason | null
  setVisitReason: (value: VisitReason | null) => void
  now: Date
  date: Date
  setDate: (value: Date) => void
  slot: AvailablePhysicianSlot | null
  setSlot: (value: AvailablePhysicianSlot) => void
  setIsLoading: (e: boolean) => void
  isLoading: boolean
}

const BookAppointment: FC<BookAppointmentProps> = ({
  patientId,
  visitReason,
  setVisitReason,
  date,
  setDate,
  now,
  slot,
  setSlot,
  setIsLoading,
  isLoading,
}) => {
  return (
    <>
      <Box m={2} display={visitReason ? 'block' : 'none'}>
        <SelectAppointmentType
          patientId={patientId}
          visitReason={visitReason}
          setVisitReason={setVisitReason}
          setIsLoading={setIsLoading}
        />
      </Box>
      {visitReason ? (
        <Box m={2}>
          <SelectDate date={date} setDate={setDate} now={now} />

          <SelectSlot
            date={date}
            patientId={patientId}
            visitReason={visitReason}
            slot={slot}
            setSlot={setSlot}
            setIsLoading={setIsLoading}
          />
        </Box>
      ) : !isLoading ? (
        <Box m={2}>
          <Typography>{'Patient is not eligible to book appointment.'}</Typography>
        </Box>
      ) : null}
    </>
  )
}

export default BookAppointment
