import { Box, TextField } from '@mui/material'
import { FC, useState } from 'react'

type BookProps = {
  apptReason: string
  setApptReason: (e) => void
  saving: boolean
}

const BookingReason: FC<BookProps> = ({ apptReason, setApptReason, saving }) => {
  const [reasonError, setReasonError] = useState<boolean>(false)

  const updateApptReason = e => {
    if (e.target.value == '') {
      setReasonError(true)
    }
  }

  const handleUpdateApptReason = (event: any) => {
    if (event) {
      setApptReason(event.target?.value)
      if (event.target.value !== '') {
        setReasonError(false)
      } else {
        setReasonError(true)
      }
    }
  }

  return (
    <Box m={2}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={apptReason}
        onChange={e => handleUpdateApptReason(e)}
        multiline={true}
        fullWidth
        placeholder="Reason for visit (Member-Facing)"
        disabled={saving}
        error={reasonError}
        helperText={reasonError && 'Reason for visit required'}
        onBlur={updateApptReason}
      />
    </Box>
  )
}

export default BookingReason
