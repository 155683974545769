import { useQueryClient } from 'react-query'
import { apiClient } from '~/api/rest'
import { useQuery, useMutation } from '~/components/Providers/ApiProvider'
import { IBenefitProgram } from '~/models/Program'
import { IProgram, IProgramEnrollment, IProgramEnrollmentEvent } from '~/models/ProgramEnrollment'

async function getBenefitProgramForPatient(patientId: number): Promise<IBenefitProgram> {
  const data = await apiClient.rest.get<IBenefitProgram>(`/programs/benefit/${patientId}/`)
  return data
}

const getBenefitProgramKey = 'getBenefitProgram'
export const useBenefitProgram = (variables: { patientId: number }) => {
  const { patientId } = variables
  return useQuery(
    [getBenefitProgramKey, patientId],
    () => getBenefitProgramForPatient(patientId),
    {},
    {
      error: 'Failed to fetch benefit program',
    }
  )
}

async function listPrograms(): Promise<IProgram[]> {
  const data = await apiClient.rest.get(`/programs/`)
  return data
}

const listProgramsKey = 'listPrograms'
export const usePrograms = () => {
  const { data, isLoading } = useQuery(
    [listProgramsKey],
    listPrograms,
    {
      staleTime: Infinity,
    },
    {
      error: 'Failed to fetch programs',
    }
  )

  return { result: data || [], isLoading }
}

const listProgramEnrollments = async (personId: number): Promise<IProgramEnrollment[]> =>
  apiClient.rest.get<any>(`/programs/people/${personId}/enrollment/`)

const listProgramEnrollmentsKey = 'listProgramEnrollments'
export const useListProgramEnrollments = (personId: number) => {
  return useQuery(
    [listProgramEnrollmentsKey, personId],
    () => listProgramEnrollments(personId!),
    {
      enabled: personId !== null,
    },
    {
      error: 'Failed to fetch program enrollments',
    }
  )
}

export const useListProgramEnrollmentByProgram = (personId: number) => {
  const { data: programEnrollments, isLoading } = useListProgramEnrollments(personId)
  const programEnrollmentMap = new Map()
  if (programEnrollments) {
    for (const programEnrollment of programEnrollments) {
      let enrollments: any[] = []
      if (programEnrollmentMap.has(programEnrollment.program)) {
        enrollments = programEnrollmentMap.get(programEnrollment.program)
      }
      enrollments.push(programEnrollment)
      programEnrollmentMap.set(programEnrollment.program, enrollments)
    }
  }
  const result = programEnrollmentMap
  return { result, isLoading }
}

const programEnrollmentEvent = async (
  payload: IProgramEnrollmentEvent
): Promise<IProgramEnrollmentEvent> => {
  await apiClient.rest.post(
    `/programs/${payload.programUid}/people/${payload.personId}/enrollment/event/`,
    {
      event: payload.event,
      eventDate: payload.eventDate,
      reason: payload.reason,
    }
  )
  return payload
}

export const useProgramEnrollmentEvent = () => {
  const client = useQueryClient()

  return useMutation(
    programEnrollmentEvent,
    {
      onSuccess: async (p: IProgramEnrollmentEvent) => {
        await client.cancelQueries([listProgramEnrollmentsKey, p.personId])
        await client.invalidateQueries([listProgramEnrollmentsKey, p.personId])
      },
    },
    { error: 'Failed to post program enrollment event' }
  )
}
