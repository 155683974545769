import { useEffect, useState } from 'react'
import type { ReactNode } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { apiClientWithoutRequestDecamelization as apiClientWithoutRequestDecamelization } from '~/api/rest'
import Loader from '~/components/Loader'
import { ChipTabs } from '~/components/Generic/ChipTabs'
import CancellationsTab from './CancellationsTab'
import { useQuery } from '~/components/Providers/ApiProvider'
import BookAppointmentModal from './BookAppointment/BookAppointmentModal'
import AppointmentsTab from './AppointmentsTab'
import ConfirmCancelApptModal from './ConfirmCancelApptModal'
import CancelAppointmentModal from './CancelAppointmentModal'
import DeleteAppointmentModal from './DeleteAppointmentModal'

interface PatientDetailAppointmentsProp {
  patient: any
}

interface TabPanelProps {
  children: ReactNode
  value: AppointmentTabTypes
  selectedTab: AppointmentTabTypes
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, selectedTab } = props
  const visible = value === selectedTab

  return (
    <div
      role="tabpanel"
      hidden={!visible}
      id={`appointment-tabpanel-${value}`}
      aria-labelledby={`appointment-tab-${value}`}
    >
      {visible && <Box p={3}>{children}</Box>}
    </div>
  )
}

type AppointmentTabTypes = 'appointments' | 'cancellations'

const STATES = {
  INIT: 0,
  BOOKING: 1,
  CANCELING: 2,
  CONFIRMING_CANCEL: 3,
  DELETING: 4,
} as const

export const useGetPatientAppointmentsInfo = (patientId: number) =>
  useQuery(
    ['getPatientAppointmentsInfo', patientId],
    async () => {
      // TODO: the type here is BffCrmPatientAppointmentsRetrieveResponse
      // but imports from @fireflyhealth/api are not working (Module parse failed...)
      return await apiClientWithoutRequestDecamelization.rest.get<any>(
        `/bff/crm/patient-appointments/?patient_id=${patientId}`
      )
    },
    { staleTime: Infinity, enabled: !!patientId }
  )

export const useGetPatientAppointmentCancelationsInfo = (patientId: number) =>
  useQuery(
    ['getPatientAppointmentCancelationInfo', patientId],
    async () => {
      // TODO: the type here is BffCrmPatientAppointmentsRetrieveResponse
      // but imports from @fireflyhealth/api are not working (Module parse failed...)
      return await apiClientWithoutRequestDecamelization.rest.get<any>(
        `/bff/crm/patient-appointment-cancelations/?patient_id=${patientId}`
      )
    },
    { staleTime: Infinity, enabled: !!patientId }
  )

export type Appointment = Awaited<ReturnType<typeof useGetPatientAppointmentsInfo>>['data']
export type AppointmentCancelation = Awaited<
  ReturnType<typeof useGetPatientAppointmentCancelationsInfo>
>['data']

const PatientDetailAppointments = (props: PatientDetailAppointmentsProp) => {
  const { data: appointments, isLoading: loadingAppointments } = useGetPatientAppointmentsInfo(
    props.patient.id
  )
  const { data: appointmentCancelations, isLoading: loadingAppointmentCancelations } =
    useGetPatientAppointmentCancelationsInfo(props.patient.id)

  const [tab, setTab] = useState<AppointmentTabTypes>('appointments')
  const [currentAppointment, setCurrentAppointment] = useState<Appointment | null>(null)
  const [state, setState] = useState<typeof STATES[keyof typeof STATES]>(STATES.INIT)

  // Cancelation state
  const [otherReasonForFirefly, setOtherReasonForFirefly] = useState<string>('')
  const [cancellationReasonIds, setCancellationReasonIds] = useState<Array<number>>([])
  const [otherReasonForPatient, setOtherReasonForPatient] = useState<string>('')

  useEffect(() => {
    if (state === STATES.INIT) {
      setCurrentAppointment(null)
      setCancellationReasonIds([])
      setOtherReasonForPatient('')
      setOtherReasonForFirefly('')
    }
  }, [state])

  if (loadingAppointments) {
    return <Loader />
  }

  const handleBookAppt = () => {
    setState(STATES.BOOKING)
  }

  const cancelations =
    !appointmentCancelations || !appointmentCancelations.cancelationEvents
      ? 0
      : appointmentCancelations.cancelationEvents.length

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" m={2}>
        <ChipTabs
          tabs={[
            { label: `Appointments (${appointments.appointments.length})`, value: 'appointments' },
            {
              label: `Cancellations (${cancelations})`,
              value: 'cancellations',
            },
          ]}
          selectedTab={tab}
          onSelect={setTab}
        />

        <Button
          onClick={handleBookAppt}
          variant="contained"
          size="small"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
            width: '20%',
            minHeight: '32px',
            marginRight: 2,
            justifySelf: 'flex-end',
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>Book Appointment</Typography>
        </Button>
      </Box>

      <TabPanel value="appointments" selectedTab={tab}>
        <AppointmentsTab
          patientId={props.patient.id}
          data={appointments}
          isLoading={loadingAppointments}
          selectAppointmentForCancel={appointment => {
            setCurrentAppointment(appointment)
            setState(STATES.CANCELING)
          }}
        />
      </TabPanel>

      <TabPanel value="cancellations" selectedTab={tab}>
        <CancellationsTab
          patientId={props.patient.id}
          data={appointmentCancelations}
          isLoading={loadingAppointmentCancelations}
          selectAppointmentForCancel={appointment => {
            setCurrentAppointment(appointment)
            setState(STATES.CANCELING)
          }}
        />
      </TabPanel>
      {state === STATES.BOOKING ? (
        <BookAppointmentModal
          patientId={props.patient.id}
          onClose={() => setState(STATES.INIT)}
        ></BookAppointmentModal>
      ) : null}
      {state === STATES.CANCELING ? (
        <CancelAppointmentModal
          appointment={currentAppointment}
          cancellationReasonIds={cancellationReasonIds}
          setCancellationReasonIds={setCancellationReasonIds}
          otherReasonForPatient={otherReasonForPatient}
          otherReasonForFirefly={otherReasonForFirefly}
          setOtherReasonForPatient={setOtherReasonForPatient}
          setOtherReasonForFirefly={setOtherReasonForFirefly}
          onClose={success =>
            success ? setState(STATES.CONFIRMING_CANCEL) : setState(STATES.INIT)
          }
        />
      ) : null}
      {state === STATES.CONFIRMING_CANCEL ? (
        <ConfirmCancelApptModal
          appointment={currentAppointment}
          cancellationReasonIds={cancellationReasonIds}
          otherReasonForPatient={otherReasonForPatient}
          otherReasonForFirefly={otherReasonForFirefly}
          onClose={continueToBooking =>
            continueToBooking ? setState(STATES.BOOKING) : setState(STATES.INIT)
          }
        />
      ) : null}
      {state === STATES.DELETING ? (
        <DeleteAppointmentModal
          appointment={currentAppointment}
          onClose={() => setState(STATES.INIT)}
        />
      ) : null}
    </Box>
  )
}

export default PatientDetailAppointments
