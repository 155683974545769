import Valid from '~/assets/images/patient_status_valid_alert.svg'
import Exclamation_Alert from '~/assets/images/patient_status_exclamation alert.svg'
import Invalid from '~/assets/images/patient_status_invalid_alert.svg'

import { useMemo, useState, useEffect } from 'react'
import type { FC } from 'react'
import Moment from 'moment'
import { makeStyles } from 'tss-react/mui'
import { Typography, Box, IconButton } from '@mui/material'
import {
  Info as InformationIcon,
  Cake as CakeIcon,
  Comment,
  AddCommentOutlined,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material'
import { useFeatureAccess } from '~/utils/useFeatureAccess'
import { keyBy } from 'lodash/fp'
import PrimaryCareChip from './PrimaryCareChip/PrimaryCareChip'
import { formatUserDateOfBirth, getUserAge, getUserById } from '~/utils'
import ArrowTooltip from '~/components/Generic/ArrowTooltip'
import usePatientDetail from '~/components/PatientDetail/utils/usePatientDetail'
import { UserAvatar } from '~/components/Generic/UserAvatar'
import { IPatientInsuranceDetails } from '~/utils/pubnub'
import { PatientSegmentationInfo } from './PatientSegmentationInfo'

import {
  CARE_TEAM_ROLE_GROUPS,
  ROLE_TO_ABBREVIATED,
  CARE_TEAM_CAPTION,
} from '~/constants/careTeamRoles'
import { ProviderUser, PatientUser, BehindProviderFeatureAccessCheck } from '@fireflyhealth/core'
import PatientDetailPopover from './PatientDetailPopover'
import Popover from '@mui/material/Popover'
import { useDispatch } from 'react-redux'
import patientsSlice from '~/redux/slices/patients'
import { Button, TextField } from '@mui/material'
import { PatientStateAndTimeInfo } from './PatientStateAndTimeInfo'
import ElationLink from './ElationLink'
import { usePatientReferralProgram } from './utils/usePatientReferralProgram'
import { PatientContactInfo } from './PatientContactInfo'
import { usePersonSegmentation } from '~/api/UserSegmentationService'

interface PatientDetailTopBarProps {
  patient: PatientUser
  providers: ProviderUser[]
}

export type PatientDetailPopOverTabValueTypes = 'contact_info' | 'onboarding'
export type PatientDetailPopOverTabLabelTypes = 'Contact Info' | 'Onboarding'
export type patientDetailsActiveTabsType = {
  value: PatientDetailPopOverTabValueTypes
  label: PatientDetailPopOverTabLabelTypes
  component: FC<any>
}

const UserStatusIcon = {
  Established: Valid,
  'Do not provide care': Invalid,
  'Not Established': Invalid,
  'FAST ICU': Exclamation_Alert,
  'Escalating Behavior': Exclamation_Alert,
}

const PatientDetailTopBar: FC<PatientDetailTopBarProps> = props => {
  const [popoverAnchor, setPopoverAnchor] = useState(null)
  const {
    patient: {
      firstName,
      lastName,
      person: {
        elationUrl,
        preferredName,
        dob,
        sex,
        careTeam,
        insuranceInfo,
        preferredLanguageInfo,
        pronouns,
        providerNote,
        providerNoteLastUpdatedAt,
      },
    },
    providers,
  } = props
  const isPerson = !props.patient.id
  const id = props.patient.id || props.patient.person.id

  const { classes } = useStyles()

  const { onboardingState, patientIsInProgram } = usePatientDetail()
  const styledPreferredName = preferredName ? ` (${preferredName}) ` : ' '
  const name = `${firstName}${styledPreferredName}${lastName}`
  const initials = `${firstName.substr(0, 1)}${lastName.substr(0, 1)}`
  const dobFormatted = formatUserDateOfBirth(dob)
  const isPatientBirthday = Moment(dob).format('MM/DD') === Moment().format('MM/DD')
  const [anchorEl, setAnchorEl] = useState(null)
  const [updatedProviderNote, setUpdatedProviderNote] = useState(props.patient.person.providerNote)
  const [showStickyNoteEditDialog, setShowStickyNoteEditDialog] = useState(false)
  const featureAccess = useFeatureAccess()
  const popoverOpen = Boolean(anchorEl)
  const {
    result: personSegmentation,
    isLoading: isLoadingGetPersonSegmentation,
    error: errorPersonSegmentation,
  } = usePersonSegmentation(props.patient.person.id)
  const [patientDetailsActiveTabs, setPatientDetailsActiveTabs] = useState<
    patientDetailsActiveTabsType[]
  >([{ value: 'contact_info', label: 'Contact Info', component: PatientContactInfo }])

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setShowStickyNoteEditDialog(false)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (updatedProviderNote != props.patient.person.providerNote) {
      const person = props.patient.person
      const personWithoutAvatar = { ...person, avatar: undefined, avatarLarge: undefined }
      let newPerson = JSON.parse(JSON.stringify(personWithoutAvatar))
      newPerson.providerNote = updatedProviderNote
      try {
        dispatch(
          patientsSlice.thunks.updatePatient({
            id: props.patient.id,
            firstName: props.patient.firstName,
            lastName: props.patient.lastName,
            person: newPerson,
          })
        )
      } catch (e) {
        console.error(e)
      }
    }
  }, [updatedProviderNote])

  useEffect(() => {
    if (!errorPersonSegmentation && personSegmentation && personSegmentation?.completedAt) {
      const segmentationTab: patientDetailsActiveTabsType[] = [
        { value: 'onboarding', label: 'Onboarding', component: PatientSegmentationInfo },
      ]
      const activeTabs: patientDetailsActiveTabsType[] =
        segmentationTab.concat(patientDetailsActiveTabs)
      setPatientDetailsActiveTabs(activeTabs)
    } else {
      setPatientDetailsActiveTabs([
        { value: 'contact_info', label: 'Contact Info', component: PatientContactInfo },
      ])
    }
  }, [props.patient, personSegmentation, isLoadingGetPersonSegmentation, errorPersonSegmentation])

  if (!Array.isArray(careTeam)) {
    // TODO: remove this once we determine cause of weirdly structured objects
    console.error(`Unexpected care team structure: ${JSON.stringify(props.patient)}`)
  }

  const patientCareTeam = useMemo(
    () =>
      Array.isArray(careTeam) ? careTeam.map(providerId => getUserById(providers, providerId)) : [],
    [careTeam, providers]
  )

  const patientCareTeamRole = keyBy(provider => {
    const groupFound = provider?.groups?.find(g => CARE_TEAM_ROLE_GROUPS.has(g.name))
    return groupFound ? groupFound.name : 'unknown'
  }, patientCareTeam)

  const getProviderNameRole = roleName => {
    const provider = patientCareTeamRole[roleName]
    if (!provider) {
      return ''
    }

    return `${provider.firstName} ${provider.lastName}  (${ROLE_TO_ABBREVIATED[roleName]})`
  }

  const { data: patientReferralProgram } = usePatientReferralProgram(props.patient)

  const patientBio = () => {
    const preferredLanguage =
      preferredLanguageInfo && preferredLanguageInfo.name !== 'English'
        ? preferredLanguageInfo.name
        : null
    const bioInfo = [
      `ID #${id}`,
      ...(pronouns ? [`${sex} (${pronouns})`] : [sex]),
      `${getUserAge(dob)} (${dobFormatted})`,
      ...(preferredLanguage ? [preferredLanguage] : []),
    ]
    return bioInfo.join(' · ')
  }

  const careTeamRoleCaption = CARE_TEAM_CAPTION.filter(role => patientCareTeamRole[role])
    .map(getProviderNameRole)
    .join(' · ')

  const getPatientInsuranceDetails = () => {
    const patientInsurance = props.patient.person?.insuranceInfo
    if (patientInsurance) {
      return {
        coverageStartDate: patientInsurance.coverageStart,
        coverageEndDate: patientInsurance.coverageEnd,
        insurancePayerName: patientInsurance.insurancePayer?.name ?? '',
      } as IPatientInsuranceDetails
    }
    return null
  }

  const handleStickyNotePopoverEvent = event => {
    setShowStickyNoteEditDialog(true)
    handlePopoverOpen(event)
  }

  const handleStickyNoteSave = note => {
    setUpdatedProviderNote(note)
    handlePopoverClose()
  }

  const noteLastUpdatedFormatted = Moment(providerNoteLastUpdatedAt).format('MMM DD YYYY')

  return props.patient != null &&
    props.patient.person != null &&
    Object.keys(props.patient.person).length > 0 ? (
    <Box display="flex" alignItems="center" p={1}>
      <Box display="flex" flex="1" alignItems="center">
        <Box mr={1} alignSelf="baseline">
          <UserAvatar id={id} src={props.patient.person.avatar || undefined}>
            {initials}
          </UserAvatar>
        </Box>
        <div>
          <Box display="flex" alignItems="center">
            <Typography variant="h6">{name}</Typography>
            <BehindProviderFeatureAccessCheck feature="programs" featureAccess={featureAccess}>
              <Box position="relative" ml={1} className={classes.chipContainer}>
                <PrimaryCareChip
                  onboardingStatus={onboardingState.status}
                  patientIsInPrimaryCareProgram={patientIsInProgram('PrimaryCare')}
                  patientIsInBenefitProgram={patientIsInProgram('Benefit')}
                  coverage={props.patient.person.coverage}
                  patientInsuranceInfo={getPatientInsuranceDetails()}
                  riskScore={props.patient.person?.primaryCareProgramInfo?.riskScore}
                />
              </Box>
            </BehindProviderFeatureAccessCheck>
          </Box>
          <Box color="black">
            <Typography data-testid="patient-bio" variant="caption">
              {patientBio()}
              {' · '}
              <PatientStateAndTimeInfo patientState={insuranceInfo?.addresses?.[0]?.state} />
            </Typography>
            {isPatientBirthday && (
              <Typography variant="caption" color="primary">
                <CakeIcon className={classes.cakeIcon} color="inherit" fontSize="small" />
                Today
              </Typography>
            )}
          </Box>
          <Box display="flex" mt={-0.5} color="black">
            {props.patient.userStatus && (
              <>
                <Box mt={0.3}>
                  <img
                    src={UserStatusIcon[props.patient.userStatus]}
                    alt="Patient Status"
                    style={{ width: 'auto', height: '1.5rem' }}
                  />
                </Box>
                <Box ml={0.3}>
                  <Typography variant="caption">{props.patient.userStatus}</Typography>
                </Box>
              </>
            )}
            {!isPerson ? (
              <Box ml={0.7}>
                <BehindProviderFeatureAccessCheck feature="carePlans" featureAccess={featureAccess}>
                  <Typography variant="caption"> · </Typography>
                  {patientCareTeam.length === 0 ? (
                    <Typography variant="caption">No Care Team Assigned</Typography>
                  ) : (
                    <Typography variant="caption">{careTeamRoleCaption}</Typography>
                  )}
                </BehindProviderFeatureAccessCheck>
              </Box>
            ) : null}
          </Box>
        </div>
      </Box>

      {!isPerson ? (
        <>
          <Box mt={-5} mr={-1.25}>
            {providerNote ? (
              <>
                <IconButton color="inherit" onClick={handleStickyNotePopoverEvent}>
                  <Comment onMouseEnter={handlePopoverOpen} style={{ color: 'FFB547' }} />
                </IconButton>

                <Popover
                  id="mouse-over-popover"
                  open={popoverOpen}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    popover: {
                      pointerEvents: 'none',
                    },
                  }}
                  onClose={handlePopoverClose}
                  disableEnforceFocus={true}
                >
                  {showStickyNoteEditDialog ? (
                    <div style={{ padding: 10 }}>
                      <StickyNoteEditDialog
                        handleStickyNoteSave={handleStickyNoteSave}
                        previousNote={providerNote}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ padding: 10 }}
                      role="button"
                      tabIndex={0}
                      onClick={() => setShowStickyNoteEditDialog(true)}
                      onKeyDown={() => setShowStickyNoteEditDialog(true)}
                    >
                      <Typography>{providerNote}</Typography>
                      <Typography variant="caption">
                        Last Updated: {noteLastUpdatedFormatted}
                      </Typography>
                    </div>
                  )}
                </Popover>
              </>
            ) : (
              <>
                <IconButton color="inherit" onClick={handleStickyNotePopoverEvent}>
                  <AddCommentOutlined />
                </IconButton>
                <Popover
                  id="sticky-note-form-popover"
                  open={popoverOpen}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {showStickyNoteEditDialog && (
                    <div style={{ padding: 10 }}>
                      <StickyNoteEditDialog handleStickyNoteSave={handleStickyNoteSave} />
                    </div>
                  )}
                </Popover>
              </>
            )}
          </Box>
          <Box mt={-5} color="navy.500">
            <ArrowTooltip
              title={
                personSegmentation && !errorPersonSegmentation
                  ? 'Onboarding & Contact Info'
                  : 'Contact Info'
              }
            >
              <IconButton onClick={e => setPopoverAnchor(e.currentTarget as any)} color="inherit">
                <InformationIcon
                  color={personSegmentation && !errorPersonSegmentation ? 'primary' : 'inherit'}
                />
              </IconButton>
            </ArrowTooltip>
            {popoverAnchor && patientReferralProgram && (
              <PatientDetailPopover
                patient={props.patient}
                userSegmentation={personSegmentation}
                anchorEl={popoverAnchor!}
                onClose={() => setPopoverAnchor(null)}
                patientReferralProgram={patientReferralProgram?.patientReferralProgram}
                activeTabs={patientDetailsActiveTabs}
              />
            )}
          </Box>
          <BehindProviderFeatureAccessCheck
            feature="elationIntegration"
            featureAccess={featureAccess}
          >
            <Box mt={-5} mr={1}>
              <ElationLink elationUrl={elationUrl} />
            </Box>
          </BehindProviderFeatureAccessCheck>
        </>
      ) : null}
    </Box>
  ) : (
    <div></div>
  )
}

interface StickyNoteEditDialogProps {
  handleStickyNoteSave: Function
  previousNote?: string
}

const StickyNoteEditDialog: FC<StickyNoteEditDialogProps> = props => {
  const { handleStickyNoteSave, previousNote } = props

  const [text, setText] = useState(previousNote ? previousNote : '')

  const { classes } = useStyles()

  const characterLimit = 150

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <br />
      <TextField
        id="standard-basic"
        className={classes.textField}
        label={`Add/Update Note: ${text.length}/${characterLimit}`}
        variant="standard"
        onChange={event => setText(event.target.value)}
        defaultValue={previousNote}
        multiline
        inputProps={{ maxLength: characterLimit }}
      />
      <Box>
        <IconButton
          color="inherit"
          onClick={() => {
            handleStickyNoteSave('')
          }}
        >
          <DeleteOutlineIcon style={{ color: 'red' }} />
        </IconButton>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleStickyNoteSave(text)
          }}
          style={{ width: '30%' }}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles()(theme => ({
  cakeIcon: {
    marginLeft: '1rem',
    marginRight: '0.5rem',
    marginBottom: '-0.1em',
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  chipContainer: {
    '& .MuiChip-root': {
      marginRight: '0.1rem',
    },
    '& .MuiChip-root:not(:first-child)': {
      marginLeft: '1rem',
    },
  },
  textField: {
    width: '30rem',
  },
}))

export default PatientDetailTopBar
