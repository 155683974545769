import React from 'react'
import type { FC } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import Moment from 'moment'
import {
  Box,
  Chip,
  Collapse,
  Popover,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
} from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import IconButton from '@mui/material/IconButton'
import ArrowRight from '@mui/icons-material/ArrowRight'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import Loader from '~/components/Loader'
import { useSteerages } from '~/api/SteerageService'
import { Person } from '@fireflyhealth/core'
import { SteerageProvider, Steerage } from '~/components/PatientDetail/SteerageView/types'
import { getSteerageType } from '~/components/PatientDetail/SteerageView/SteerageView'
import { CaseCategoryUniqueEnum, CaseStatusEnum } from '~/models/Case'

export interface NavigationListProps {
  person: Person
}

const NavigationList: FC<NavigationListProps> = props => {
  const history = useHistory()
  const { data: steerages, isLoading } = useSteerages(props.person.id)
  // It's used to set the position of the popover
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedProviderPopOverData, setSelectedProviderPopOverData] =
    useState<Array<SteerageProvider> | null>(null)
  // used for collapsable row for the table
  const [open, setOpen] = useState<number | null>(null)
  // Hardcoded date for referral validation
  const showReferralThroughDate = '2021-01-01'

  const handleProviderPopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    providers: SteerageProvider[] | null
  ) => {
    setAnchorEl(event.currentTarget)
    setSelectedProviderPopOverData(providers)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setSelectedProviderPopOverData(null)
  }

  const getCreatedDate = (steerage: Steerage) => {
    // If this Steerage has a Referral, this should be the Elation Created Date
    // If this Steerage does not have a Referral, then this should be the Waiver’s Created Date

    if (steerage?.hasReferral && steerage?.referral?.vendorCreateDate)
      return Moment(steerage?.referral?.vendorCreateDate).format('MM/DD/YYYY')
    if (steerage?.hasWaiver) return Moment(steerage?.waiver?.createdAt).format('MM/DD/YYYY')
    return null
  }

  const showProviderNameColumnData = (providers: SteerageProvider[]) => {
    // first show the provider which is selected by member
    // if there is not provider selected by member then just show the first provider
    const memberSelectedProvider = providers?.find(provider => provider?.memberSelectedAt != null)

    if (memberSelectedProvider) {
      // if facility name present then show that otherwise provider name
      return memberSelectedProvider?.careOrganizationName
        ? memberSelectedProvider?.careOrganizationName
        : memberSelectedProvider.firstName + ' ' + memberSelectedProvider.lastName
    }
    // get the first provider and show that
    const provider = providers[0]
    return provider?.careOrganizationName
      ? provider?.careOrganizationName
      : provider?.firstName + ' ' + provider?.lastName
  }

  const returnToCase = e => {
    const caseId = e.currentTarget.getAttribute('data-case-id')

    if (caseId) {
      const path = location.pathname
      const basePath = path.substring(0, path.lastIndexOf('/'))
      history.replace(`${basePath}/todos?caseId=${caseId}`)
    } else {
      console.log('Case ID not found for the care pass ', caseId)
    }
  }

  const steerageListValidation = (steerage: Steerage) => {
    // Do not show row if
    // The Steerage is tied to a case of category “Referral - Location Search - Care Only”
    // OR “Care Pass from Referral (Care + Coverage)”
    // AND that Case is in status “Will Not Do”
    // AND that Steerage description is empty
    let result = steerage?.cases?.map(steerageCase => {
      if (
        (steerageCase?.category?.uniqueKey ==
          CaseCategoryUniqueEnum.referrals_location_search_care_only ||
          steerageCase?.category?.uniqueKey ==
            CaseCategoryUniqueEnum.care_pass_referral_care_and_coverage ||
          steerageCase?.category?.uniqueKey ==
            CaseCategoryUniqueEnum.care_pass_member_request_coverage_only) &&
        steerageCase?.status == CaseStatusEnum.will_not_do &&
        !steerage.description
      ) {
        return false
      }
    })
    if (result?.includes(false)) return false

    // Only include Referrals through January 2021
    if (
      steerage.isEffectivelyReferralOnly &&
      steerage?.referral?.createdAt &&
      Moment(steerage.referral.createdAt).format('YYYY-MM-DD') < showReferralThroughDate
    ) {
      return false
    }

    return true
  }

  const sortSteeragesByCreatedDate = (steerages: Steerage[]) => {
    // This method sorts the created date column
    return steerages.sort((steerageA, steerageB) => {
      // The sort() method returns a numeric value
      // If the result is negative a is sorted before b
      // If the result is positive b is sorted before a
      // If the result is 0 no changes are done
      let createdDateA = Moment(getCreatedDate(steerageA))
      let createdDateB = Moment(getCreatedDate(steerageB))
      if (createdDateA && createdDateB && createdDateA > createdDateB) {
        return -1
      }
      if (createdDateA && createdDateB && createdDateA < createdDateB) {
        return 1
      }
      return 0
    })
  }

  return (
    <Box>
      <Box mt={3} ml={4}>
        <Typography variant="subtitle1" fontWeight="500">
          Network Navigation
        </Typography>
      </Box>
      {isLoading ? <Loader /> : null}
      {/* Pop over for the provider list */}
      <Popover
        style={{
          pointerEvents: 'none',
        }}
        open={selectedProviderPopOverData && selectedProviderPopOverData.length > 0 ? true : false}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {selectedProviderPopOverData?.map(provider => (
          <Box mx={3} my={1} key={provider?.id}>
            <Typography style={{ fontSize: '14px' }}>
              {provider?.careOrganizationName
                ? provider?.careOrganizationName
                : provider?.firstName + ' ' + provider?.lastName}{' '}
              {provider.memberSelectedAt ? (
                <DoneIcon fontSize="small" style={{ verticalAlign: 'bottom' }}></DoneIcon>
              ) : null}
            </Typography>
          </Box>
        ))}
      </Popover>
      <Box mt={3} ml={2} mr={2}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell width="30%">Description</TableCell>
              <TableCell width="30%">Providers</TableCell>
              <TableCell width="20%">Created Date</TableCell>
              <TableCell width="20%">Cases</TableCell>
            </TableRow>
          </TableHead>
          {steerages &&
            sortSteeragesByCreatedDate(steerages).map(
              steerage =>
                steerageListValidation(steerage) && (
                  <React.Fragment key={steerage.id}>
                    <TableRow key={steerage.id}>
                      <TableCell component="th" scope="row">
                        {/* Show 'None' when description is null */}
                        {steerage.description ?? 'None'} {' - '} {getSteerageType(steerage)}
                      </TableCell>
                      <TableCell align="left">
                        {steerage &&
                          steerage?.steerageProviders &&
                          steerage?.steerageProviders.length > 0 && (
                            <Typography
                              style={{ fontSize: '14px' }}
                              onMouseEnter={event =>
                                handleProviderPopoverOpen(event, steerage.steerageProviders ?? null)
                              }
                              onMouseLeave={handlePopoverClose}
                            >
                              {showProviderNameColumnData(steerage?.steerageProviders)}{' '}
                              {steerage?.steerageProviders.length > 1 ? '...' : ''}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell align="left">{getCreatedDate(steerage)}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpen(open == steerage?.id ? null : steerage?.id)}
                          style={{
                            color: '#0077DC',
                            fontSize: 'inherit',
                            backgroundColor: 'transparent',
                          }}
                        >
                          {open == steerage?.id ? <ArrowDropDown /> : <ArrowRight />}
                          {steerage?.cases?.length + ' case(s)'}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open == steerage?.id} timeout="auto" unmountOnExit>
                          <Box mt={1} ml={6}>
                            <Typography variant="subtitle2" component="div">
                              Cases
                            </Typography>
                            {steerage?.cases &&
                              steerage.cases.map(steerageCase => (
                                <Box mt={1} mb={1} key={steerageCase.id}>
                                  <Typography variant="body2" component="div">
                                    <Link
                                      data-case-id={steerageCase?.id}
                                      onClick={returnToCase}
                                      style={{
                                        fontSize: '14px',
                                        color: 'black',
                                        textDecorationColor: 'black',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      {steerageCase?.category?.title}
                                    </Link>
                                    {steerageCase?.status ? (
                                      <Chip
                                        size="small"
                                        style={{
                                          fontSize: '13px',
                                          marginLeft: '10px',
                                          fontWeight: 'initial',
                                        }}
                                        label={steerageCase?.status}
                                      />
                                    ) : null}
                                  </Typography>
                                </Box>
                              ))}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )
            )}
        </Table>
      </Box>
    </Box>
  )
}

export default NavigationList
