import moment from 'moment'

export const EXCEPTIONSTAB = 'Exceptions'

export const APPROVED_STATUS = 'Approved'
export const REJECTED_STATUS = 'Rejected'

interface DayOfWeek {
  dayOfWeek: number
  fullDayName: string
}

export const TABS: { [tabName: string]: DayOfWeek } = {
  Mo: {
    dayOfWeek: 1,
    fullDayName: 'Monday',
  },
  Tu: {
    dayOfWeek: 2,
    fullDayName: 'Tuesday',
  },
  We: {
    dayOfWeek: 3,
    fullDayName: 'Wednesday',
  },
  Th: {
    dayOfWeek: 4,
    fullDayName: 'Thursday',
  },
  Fr: {
    dayOfWeek: 5,
    fullDayName: 'Friday',
  },
  Sa: {
    dayOfWeek: 6,
    fullDayName: 'Saturday',
  },
  Su: {
    dayOfWeek: 7,
    fullDayName: 'Sunday',
  },
}

export const TAB_KEYS = [...Object.keys(TABS), EXCEPTIONSTAB]

export type Provider = {
  pk: number
  firstName: string
  lastName: string
  physician: number
}

type EffectivePeriod = {
  lower: string
  upper: string | null
  bounds: string
}

type Period = {
  lower: string
  upper: string
  bounds: string
}

export type StagingShiftCreate = {
  dayOfWeek: number
  startTime: string
  stopTime: string
  effectivePeriod: EffectivePeriod
}

export type StagingShiftExceptionCreate = {
  period: Period
  reason: string | null
}

export type IngestionJobCreate = {
  providerId: number
  stagingShifts?: StagingShiftCreate[]
  stagingShiftExceptions?: StagingShiftExceptionCreate[]
}

export type IngestionJob = {
  reviewStatus: string
  id: number
  providerId: number
  stagingShifts: StagingShift[]
  stagingShiftExceptions: StagingShiftException[]
}

type ImpactedAppointment = {
  id: number
  shortReason: string
  startTime: string
  affectedPatient: number | null
  patientUrl: string | null
}

export type IngestionImpact = {
  validationErrors: string[]
  impactedAppointments: ImpactedAppointment[]
  impactedAppointmentSlots: ImpactedAppointment[]
}

export type IngestionJobWithImpact = {
  id: number
  providerId: number
  stagingShifts: StagingShift[]
  stagingShiftExceptions: StagingShiftException[]
  ingestionImpact: IngestionImpact
}

export type IngestionJobStatus = {
  id: number
  reviewStatus: string
}

export type StagingShift = {
  id: number
  scheduleId: number
  dayOfWeek: number
  startTime: string
  stopTime: string
  effectivePeriod: EffectivePeriod
  ingestionJobId: number
}

export type StagingShiftException = {
  id: number
  scheduleId: number
  period: Period
  reason: string | null
  ingestionJobId: number
}

export type Shift = {
  id: number
  dayOfWeek: number
  startTime: string
  stopTime: string
  effectivePeriod: EffectivePeriod
  isEdited?: boolean
}

export type ShiftException = {
  id: number
  period: Period
  reason: string | null
  isEdited?: boolean
  isAllDay?: boolean
}

type VisitMixRatio = {
  id: number | null
  percentageOfSlots: number
  dayOfWeek: number
}

export type AppointmentType = {
  uniqueKey: string
  name: string
  id: number
}

type AppointmentTypeMapping = {
  visitMixRatios: VisitMixRatio[]
  appointmentType: AppointmentType
}

export type Schedule = {
  id: number
  provider: Provider
  shifts: Shift[]
  shiftExceptions: ShiftException[]
  ingestionJobs: IngestionJob[]
  appointmentTypeMappings: AppointmentTypeMapping[]
}

export type CalendarEvent = {
  start: Date
  end: Date
  title: string
}

export interface VisitMix {
  percentage: number
  id: number | null
  isEdited?: boolean
}

export interface VisitMixCreate {
  percentageOfSlots: number
  id: number | null
  physicianId: number
  appointmentTypeId: number
  dayOfWeek: number
}

export const formatTime = (timeString: string) => {
  const [hourString, minute] = timeString.split(':')
  const hour = +hourString % 24
  return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' am' : ' pm')
}

export const formatDate = (dateString: string | null) => {
  if (dateString)
    return new Date(dateString).toLocaleDateString('en-US', {
      timeZone: 'UTC',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
  return '–'
}

const periodDateTime = (dateTimeString: string) =>
  moment(dateTimeString).tz('America/New_York').format('YYYY-MM-DDTHH:mm')

const formatDateWeekday = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    timeZone: 'America/New_York',
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}

const formatTimePeriod = (dateTimeString: string) => {
  return new Date(dateTimeString).toLocaleTimeString('en-US', {
    timeZone: 'America/New_York',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })
}

export const formatEffectivePeriod = ({ lower, upper }) => {
  return `Starts: ${formatDate(lower)}, Ends: ${formatDate(upper)}`
}

export const formatPeriodDate = ({ lower, upper }) => {
  if (periodDateTime(lower).slice(0, 10) === periodDateTime(upper).slice(0, 10))
    return formatDateWeekday(lower)
  return `${formatDateWeekday(lower)} – ${formatDateWeekday(upper)}`
}

export const formatPeriodTime = ({ lower, upper }) => {
  if (periodDateTime(lower).slice(0, 10) === periodDateTime(upper).slice(0, 10))
    if (
      periodDateTime(lower).slice(11, 16) === '00:00' &&
      periodDateTime(upper).slice(11, 16) === '23:59'
    )
      return 'All Day'
    else return `${formatTimePeriod(lower)} – ${formatTimePeriod(upper)}`
  return 'All Day'
}

export const getIsAllDay = ({ lower, upper }) => {
  if (
    periodDateTime(lower).slice(11, 16) === '00:00' &&
    periodDateTime(upper).slice(11, 16) === '23:59'
  )
    return true
  return false
}

export const formatProviderStatus = (providerName, reviewStatus) => {
  if (reviewStatus == 'Approved') return `${providerName}: Ingestion job in Progress`
  return `${providerName}: Unsaved Changes`
}

export const getShifts = (stagingShifts: StagingShift[]): Shift[] =>
  stagingShifts.map(stagingShift => {
    return {
      id: stagingShift.id,
      dayOfWeek: stagingShift.dayOfWeek,
      startTime: stagingShift.startTime,
      stopTime: stagingShift.stopTime,
      effectivePeriod: stagingShift.effectivePeriod,
      isEdited: false,
    }
  })

export const getShiftExceptions = (
  stagingShiftExceptions: StagingShiftException[]
): ShiftException[] =>
  stagingShiftExceptions.map(stagingShiftException => {
    return {
      id: stagingShiftException.id,
      period: stagingShiftException.period,
      reason: stagingShiftException.reason,
      isEdited: false,
      isAllDay: getIsAllDay(stagingShiftException.period),
    }
  })

export const sortShiftFunction = (a: Shift, b: Shift) =>
  a.startTime == b.startTime
    ? a.effectivePeriod.lower < b.effectivePeriod.lower
      ? -1
      : 1
    : a.startTime < b.startTime
    ? -1
    : 1

export const sortShiftExceptionFunction = (a: ShiftException, b: ShiftException) =>
  // compare period lower. If lower is equal, compare the upper
  a.period.lower == b.period.lower
    ? a.period.upper < b.period.upper
      ? -1
      : 1
    : a.period.lower < b.period.lower
    ? -1
    : 1
